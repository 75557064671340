<template>
<div class="main">
  <div class="head">
    <LuckyWheel ref="myLucky" width="8rem" height="8rem" :blocks="blocks" :prizes="prizes" :buttons="buttons" @start="startCallBack" />
  </div>
  <div class="lotteryNum">
    剩余 {{lotteryNum}} 次抽奖机会
  </div>
  <div class="onlottery">
    <div class="list">
      <dl v-for="item in list" v-bind:key="item.id">
        <dt>{{item.nickname}} <i>{{item.create_time}}</i></dt>
        <dd>中奖获得了{{item.name}}</dd>
      </dl>
    </div>
  </div>
  <div class="rules"> </div>
  <van-popup v-model="fullInfo" position="bottom" :style="{ height: '7rem' }" :close-on-click-overlay="false" class="full" :closeable="true">
    <van-form @submit="onSubmit" label-width="1rem">
      <van-field v-model="form.name" label="姓名" placeholder="姓名" />
      <van-field v-model="form.phone" label="电话" placeholder="电话" />
      <van-field v-model="form.wechat" label="微信" placeholder="微信" />
      <div style="margin: 16px;">
        <van-button round block type="info" native-type="submit">提交</van-button>
      </div>
    </van-form>
  </van-popup>
</div>
</template>

<script>
import { Dialog } from 'vant';
import { getOauth2, getInfo, getLottery, fullLottery } from '@/api/fivetwo'
import { setToken, getToken } from '@/utils/token'
export default {
  data() {
    return {
      lotteryNum: 0,
      fullInfo: false,
      form: {
        id: 0,
        name: "",
        phone: "",
        wechat: ""
      },
      list: [],
      // 以下皆为抽奖面板设置
      blocks: [{
        padding: '35px',
        imgs: [{
          src: require('../src/assets/bg.png'),
          width: '100%',
        }]
      }],
      prizes: [],
      buttons: [{
        radius: '30%',
        imgs: [{
          src: require('../src/assets/button.png'),
          width: '100%',
          top: '-130%'
        }]
      }, ],
    }
  },
  mounted() {
    this.WeiXinLogin()
  },
  methods: {
    async WeiXinLogin() {
      // 获取code
      const code = this.getUrlParam('code')
      if (code == null || code === '') {
        const Token = getToken()
        if (Token == null || Token === '') {
          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx17a588783dcf079c&redirect_uri=https://ft.moyouke.com&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
          // window.location.href = 'http://127.0.0.1:8080?code=aaaa&state=STATE'
        } else {
          const { data } = await getInfo({})
          this.setup(data)
        }
      } else {
        // 微信端获取用户信息
        const { data } = await getOauth2({
          code: code,
          state: "STATE"
        })
        setToken(data.token)
        window.location.href = "https://ft.moyouke.com"
        // this.setup(data)
      }
    },
    setup(data) {
      this.prizes = []
      for (let i = 0; i < data.prizes.length; i++) {
        this.prizes.push({ id: data.prizes[i].id, background: i % 2 == 0 ? '#FEEBB3' : '#ffffff', fonts: [{ text: data.prizes[i].name, top: 10, fontColor: "#FD5351" }] })
      }
      this.lotteryNum = data.lottery_num
      this.list = data.lottery_list
    },
    getUrlParam(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      let url = window.location.href.split('#')[0]
      let search = url.split('?')[1]
      if (search) {
        var r = search.substr(0).match(reg)
        if (r !== null)
          return unescape(r[2])
        return null
      } else
        return null
    },
    async onSubmit() {
      if (this.form.id == 0 || this.form.name == "" || this.form.phone == "" || this.form.wechat == "") {
        Dialog({ message: '请填写您的联系信息' });
        return
      }
      const { data } = await fullLottery(this.form)
      console.log(data)
      Dialog.alert({ message: data }).then(() => {
        this.form = this.$options.data().form
        this.fullInfo = false
      });
    },
    async startCallBack() {
      if (this.lotteryNum == 0) {
        Dialog({ message: '对不起，您还没有抽奖机会' });
        return
      }
      const { data } = await getLottery({})
      this.lotteryNum = 0
      this.$refs.myLucky.play()
      // 中奖信息ID
      this.form.id = data.id
      // 中奖奖品
      let win = 0
      for (let i = 0; i < this.prizes.length; i++) {
        if (this.prizes[i].id == data.prize.id) {
          win = i
        }
      }
      let that = this
      // 假设接口的请求速度是5s
      setTimeout(function () {
        that.$refs.myLucky.stop(win)
      }, 2000)
      setTimeout(function () {
        if (data.prize.is_out) {
          Dialog({ message: '对不起，您没有中奖' });
        } else {
          Dialog.alert({
              title: '中奖了',
              message: '恭喜您中了：' + data.prize.name + '，请填写您的联系方式方便我们对您颁奖',
            })
            .then(() => {
              that.fullInfo = true
            })
        }
      }, 5000)
    },
  }
}
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.main {
  background: url(../src/assets/bgs.jpg);
  background-size: 100%;
  height: 100%;
}

.main .head {
  height: 13.5rem;
  background: url(../src/assets/headbg.png) no-repeat;
  background-size: 100%;
  padding: 5rem 0 0 1rem;
}

.main .lotteryNum {
  color: #FFEFEE;
  font-size: .5rem;
  text-align: center;
}

.main .onlottery {
  background: url(../src/assets/onlottery.jpg) no-repeat;
  background-size: 100%;
  height: 9.786667rem;
  padding: 2.2rem 0 0 1.2rem;
}

.main .onlottery .list {
  font-size: .373333rem;
  width: 7.36rem;
  height: 6.2rem;
  overflow-y: scroll;
}

.main .onlottery .list dl {
  border-bottom: .013333rem
    /* 1/75 */
    solid #EEEEEE;
  padding: 0
    /* 20/75 */
    0 .266667rem 0;
}

.main .onlottery .list dl dt {
  color: #999999;
  margin: 0;
}

.main .onlottery .list dl dt i {
  font-size: .32rem
    /* 24/75 */
  ;
  margin-left: .4rem
    /* 30/75 */
  ;
  font-style: normal;
  color: #d0d0d0;
}

.main .onlottery .list dl dd {
  color: #333333;
  margin: 0;
}

.main .rules {
  background: url(../src/assets/rules.jpg) no-repeat;
  background-size: 100%;
  height: 9.066667rem;
}

.full {
  padding-top: 1rem;
}
</style>
