import Vue from 'vue'
import App from './App.vue'
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)
import { Popup, Dialog, Form, Field, Button } from 'vant';
Vue.use(Popup).use(Dialog).use(Form).use(Field).use(Button);
import 'vant/lib/index.less';
Vue.config.productionTip = false

new Vue({
    render: h => h(App),
}).$mount('#app')