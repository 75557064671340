import request from '@/utils/request'

// 获取数据
export async function getOauth2(data) {
  return request({
    url: '/pub/oauth2',
    method: 'post',
    data,
  })
}

// 获取数据
export async function getInfo(data) {
  return request({
    url: '/user/getInfo',
    method: 'post',
    data,
  })
}

// 抽奖
export async function getLottery(data) {
  return request({
    url: '/user/lottery',
    method: 'post',
    data,
  })
}

// 完善信息
export async function fullLottery(data) {
  return request({
    url: '/user/fullLottery',
    method: 'post',
    data,
  })
}
