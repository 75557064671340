import axios from 'axios'
import qs from 'qs'
import { Dialog } from 'vant';
import { isArray } from '@/utils/validate'
import { getToken, removeToken } from '@/utils/token'


// 操作正常Code数组
const codeVerificationArray = isArray([200, 0, '200', '0']) ? [...[200, 0, '200', '0']] : [...[
    [200, 0, '200', '0']
]]

// const baseURL = "http://127.0.0.1:1234"
const baseURL = "https://ft-api.moyouke.com"

/**
 * axios响应拦截器
 * @param data response数据
 * @param status HTTP status
 * @param statusText HTTP status text
 * @returns {Promise<*|*>}
 */
const handleData = async({ data, status = 0 }) => {
    // 若data.code存在，覆盖默认code
    let code = data && data["code"] ? data["code"] : status
        // 若code属于操作正常code，则code修正为200
    if (codeVerificationArray.indexOf(code) + 1) code = 200
    switch (code) {
        case 200:
            // 业务层级错误处理，以下是假定restful有一套统一输出格式(指不管成功与否都有相应的数据格式)情况下进行处理
            // 例如响应内容：
            // 错误内容：{ code: 1, msg: '非法参数' }
            // 正确内容：{ code: 200, data: {  }, msg: '操作正常' }
            // return data
            return data
        case 401:
            removeToken()
            window.location.reload()
            return "error"
    }
    if (data["msg"] == "令牌失效") {
        removeToken()
        window.location.reload()
    }
    Dialog({ message: data["msg"] });
    return Promise.reject(data)
}

/**
 * @description axios初始化
 */
const instance = axios.create({
    baseURL,
    timeout: 10000,
    headers: {
        'Content-Type': "application/json;charset=UTF-8",
    },
})

/**
 * @description axios请求拦截器
 */
instance.interceptors.request.use(
    (config) => {
        const token = getToken()
            // 规范写法 不可随意自定义
        if (token) config.headers['Authorization'] = `${token}`

        if (
            config.data &&
            config.headers['Content-Type'] ===
            'application/x-www-form-urlencoded;charset=UTF-8'
        )
            config.data = qs.stringify(config.data)
        return config
    },
    (error) => {
        return Promise.reject(error)
    }
)

/**
 * @description axios响应拦截器
 */
instance.interceptors.response.use(
    (response) => handleData(response),
    (error) => {
        const { response = {} } = error
        return handleData(response)
    }
)

export default instance